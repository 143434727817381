import log from "loglevel";

export interface AppConfig {
  environment: "local" | "development" | "staging" | "production";
  clientId: string;
  authProvider: string;
  authorizeEndpoint: string;
  tokenEndpoint: string;
  lookupBaseUrl: string;
  cardAPIBaseUrl: string;
  photoAPIBaseUrl: string;
  hotjarSiteId: number;
  hotjarVersion: number;
}

/**
 * Queries for the application configuration, which is expected to be set per-environment
 * @returns An app configuration object, giving information about oauth authentication
 */
export const getConfig = async (): Promise<AppConfig> => {
  const content = await fetch(`${window.location.origin}/config.json`);
  try {
    return await content.json();
  } catch {
    if (typeof process.env.REACT_APP_CLIENT_ID === "string") {
      log.warn("Using default app config");

      const authProvider =
        process.env.REACT_APP_OAUTH_PROVIDER ?? "https://api.apps.cam.ac.uk/oauth2/v1";
      return {
        environment: "local",
        clientId: process.env.REACT_APP_CLIENT_ID,
        authProvider: authProvider,
        authorizeEndpoint: process.env.REACT_APP_OAUTH_AUTH_ENDPOINT ?? `${authProvider}/auth`,
        tokenEndpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT ?? `${authProvider}/token`,
        lookupBaseUrl:
          process.env.REACT_APP_LOOKUP_BASE_URL ?? "https://api.apps.cam.ac.uk/lookup-test/v1",
        cardAPIBaseUrl:
          process.env.REACT_APP_CARD_API_BASE_URL ?? "https://api.apps.cam.ac.uk/card-dev",
        photoAPIBaseUrl:
          process.env.REACT_APP_PHOTO_API_BASE_URL ?? "https://api.apps.cam.ac.uk/photo-dev",
        hotjarSiteId: Number(process.env.REACT_APP_HOTJAR_SITE_ID) ?? 0,
        hotjarVersion: Number(process.env.REACT_APP_HOTJAR_VERSION) ?? 0,
      };
    }
    throw Error("Unable to retrieve application config");
  }
};
